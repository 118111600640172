import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import atencao from "../../img/atencao.svg"
import logo from "../../img/logo.png"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faClipboard, faCoffee, faEnvelope, faFileInvoice, faHome, faIdCard, faMapMarkerAlt, faMobileAlt, faPen, faPhone, faSave, faSearch, faTrash, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { StaticDialog, useDialog } from 'react-st-modal'
import InputMask from "react-input-mask";
import { apiUrl } from '../../comps/apiUrl';
import { toast } from 'react-toastify';

class CadastroClientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader:true,
      add:false,
      dados:{},
      items:[],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 15,
      edit:{},
      trash:{},
    };
  }

componentDidMount(){
    document.title = "Cadastro de clientes"
    this.fetchData(this.state.currentPage);
}

fetchData(page) {
  
    
    fetch(`${apiUrl}/clientes?page=${page}&limit=${this.state.itemsPerPage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',

      }
    })
      .then(response => {
        response.json().then(data=>{
         
    
    
    
    
          const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
            const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);
  
            this.setState({
              items: data.items,
              currentPage: page,
              totalPages,
              loader: false,
            });


        })
      })

    
  }

  handlePageClick = (page) => {
    this.fetchData(page);
  };
  handleNextPage = () => {
    this.fetchData(this.state.currentPage + 1);
  };

  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.fetchData(this.state.currentPage - 1);
    }
  };

  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
        
            backgroundColor: i === currentPage ? '#007bff' : '#fff',
            color: i === currentPage ? '#fff' : '#000',
            border: '1px solid #ddd',
            cursor: 'pointer',
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button className='btn btn-ligth'
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{

            cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
          }}
        >
          &lt;

        </button>
     

     {pages}
 

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
           className='btn btn-ligth'
          disabled={currentPage === totalPages}
          style={{
       
            cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
          }}
        >
          &gt;
        </button>
      </div>
    );
  }

handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };


  handleEdit = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };




  handleCpfCnpjChange = (e) => {
  
    function cnpj(v){
        v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
        v=v.replace(/^(\d{2})(\d)/,"$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
        v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
        v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
        v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
        return v
    }
  
    function cpf(v){
      v=v.replace(/\D/g,"")                    //Remove tudo o que não é dígito
      v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
      v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                                               //de novo (para o segundo bloco de números)
      v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
      return v
  }
  
  if(e.target.value.length > 18) return;
  var value = e.target.value
  if(e.target.value.length <= 14){
    this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ['doc']: cpf(value), // Atualiza apenas o campo correspondente
        },
      }));
  }else{
    this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ['doc']: cnpj(value), // Atualiza apenas o campo correspondente
        },
      }));
  }
  
  
  
    
  
     
    };





  render() {

if(this.state.loader){
    return(<div class="area-loader">
<div class="loader"></div>
    </div>)
}

    return (<>
<div class="content">
      
<div class="card">
<div class="card-header d-flex align-items-center justify-content-between">
<strong>Cadastro e Lista de Clientes</strong><button class="btn btn-primary" onClick={()=>{this.setState({add:true})}}> <FontAwesomeIcon icon={faUserPlus}/> Cadastrar cliente</button>
</div>
<div class="card-body">




{this.state.items.length > 0 ? <>
    <div class="mb-3 d-flex justify-content-center">
<div class="input-group w-50">
<input type="text" class="form-control" id="searchInput" onChange={(e)=>{
  this.setState({search:e.target.value})
}} placeholder="Pesquisar..."/>
<button class="btn btn-primary" onClick={(e)=>{
  console.log('aaaa')
  e.target.classList.add('load')
  var search = this.state.search
  fetch(`${apiUrl}/buscar-cliente?s=${search}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  .then((data) => {

    e.target.classList.remove('load')
  })
}}>
    <FontAwesomeIcon icon={faSearch}/>
</button>
</div>
</div>
    <table class="table table-striped table-bordered arvore-l">
<thead class="thead-dark">
<tr>
            <th>ID</th>
            <th>Nome do Cliente</th>
            <th>CNPJ/CPF</th>
            <th>Telefone Celular</th>
            <th>E-mail</th>
            <th>Opções</th>
          
     
        </tr>
</thead>
<tbody>
{this.state.items.map((item,index)=><>
    <tr>
            <td>{item.id}</td>
            <td>{item.nome}</td>
            <td>{item.doc}</td>
            <td>{item.telefone_celular}</td>
            <td>{item.email}</td>
            <td className='btns'>
<button className='btn btn-primary' onClick={()=>{
  this.setState({edit:item})
}}>
  <FontAwesomeIcon icon={faPen}/>
</button>
<button className='btn btn-danger' onClick={()=>{
  this.setState({trash:item})
}}>
  <FontAwesomeIcon icon={faTrash}/>
</button>


            </td>
           
           
        </tr>
</>)}
</tbody>
</table>

</> : <>Não há resultados</>}




</div>

<div className='card-footer' style={{display:'flex',justifyContent:'center'}}>
 {this.renderPagination()}
      </div>

</div>















</div>


<StaticDialog
        isOpen={Object.keys(this.state.trash).length > 0}
        title="Tem certeza de que deseja continuar?"
        onAfterClose={(result) => {
       
        this.setState({trash:{}})
        }}
    >
   
   <div style={{margin:10}}>
   Se você confirmar a exclusão, não será possível recuperar os dados posteriormente.
   </div>


   <div className='modal-fotter'>
<button className='btn btn-danger' onClick={(e)=>{
  e.target.classList.add('load')
  var id = this.state.trash.id
  fetch(`${apiUrl}/deletar-cliente`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // outras headers, se necessário
    },
    body: JSON.stringify({id}), // corpo da requisição em formato JSON
  })
    .then(response => {
      e.target.classList.remove('load')  
      if (response.ok) {
        this.setState(prevState => ({
          items: prevState.items.filter(item => item.id !== id),
          trash: {}
        }));

        toast.success('Cliente excluido com sucesso');}
     }).catch(error => {
      toast.error('Erro ao enviar dados:', error);
  });



}}>
  <FontAwesomeIcon icon={faTrash}/> Excluir
</button>
</div>


  </StaticDialog>






<StaticDialog
        isOpen={Object.keys(this.state.edit).length > 0}
        title="Editar Cliente"
        onAfterClose={(result) => {
       
        this.setState({edit:{}})
        }}
    >
   
   <div className="container">


<form id="cadastroForm" onSubmit={(e)=>{
var edit = this.state.edit;
e.preventDefault();
console.log(edit)
}}>

<div class="mb-3 mt-3">
                                <div >
                                    <label for="nome" >
                                        <FontAwesomeIcon icon={faUser}/> Nome Completo (Responsável)
                                    </label>
                                    <input type="text" class="form-control mt-3" id="nome" 
                                    value={this.state.edit.nome || ""} 
              onChange={this.handleEdit}  required=""/>
                                </div>
</div>



<div class="row">
                                <div class="col-md-6">
                                    <label for="tipo_pessoa" class="form-label">
                                     <FontAwesomeIcon icon={faClipboard}/> Tipo de Cliente
                                    </label>
                                    <select class="form-control" id="tipo_pessoa" required="" value={this.state.edit.tipo_pessoa} onChange={(e)=>{
var value = e.target.value;

if(value == "") return;


this.setState((prevState) => ({
    edit: {
      ...prevState.edit, // Mantém os outros campos do objeto `edit`
      ['tipo_pessoa']: value, // Atualiza apenas o campo correspondente
    },



  }));




                                    }}>
                                        <option value="" disabled="" selected="">Selecione...</option>
                                        <option value="fisica">Pessoa Física</option>
                                        <option value="juridica">Pessoa Jurídica</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="cnpj-cpf" class="form-label">
                                     <FontAwesomeIcon icon={faIdCard}/> CNPJ/CPF
                                    </label>
<input type="text" class="form-control" id="cnpj-cpf" required="" value={this.state.edit.doc}   onChange={(e)=>  this.handleCpfCnpjChange(e)}/>
                                </div>
</div>



<div class="row mt-3 mb-3">
                                <div class="col-md-6">
                                    <label for="inscricao-estadual" class="form-label">
                                
                                        <FontAwesomeIcon icon={faFileInvoice}/> Inscrição Estadual
                                    </label>
                                 <InputMask mask="999.999.999.999" type="text" class="form-control" id="inscricaoestadual"
                                    value={this.state.edit.inscricaoestadual}      onChange={this.handleEdit} 
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="email" class="form-label">
                                    <FontAwesomeIcon icon={faEnvelope}/>  E-mail
                                    </label>
                                    <input type="email" class="form-control" id="email"   value={this.state.edit.email}      onChange={this.handleEdit}  required=""/>
                                </div>
</div>



<div class="row mb-3">
                                <div class="col-md-6">
                                    <label for="telefone" class="form-label">
                                    <FontAwesomeIcon icon={faPhone}/> Telefone Fixo
                                    </label>
                                    <InputMask mask="99 9999-9999" type="tel" class="form-control" id="telefone"
                                    value={this.state.edit.telefone}      onChange={this.handleEdit} 
                                    required=""/>
                                </div>


                                <div class="col-md-6">
                                    <label for="telefone-celular" class="form-label">
                                    <FontAwesomeIcon icon={faMobileAlt}/> Telefone Celular
                                    </label>
                                    <InputMask mask="99 9 9999-9999" type="tel" class="form-control" id="telefone_celular"
                                     value={this.state.edit.telefone_celular}      onChange={this.handleEdit} 
                                    required=""/>
                                </div>
</div>



<div class="row mb-3">
<div class="col-md-6">
                                    <label for="cep" class="form-label">
                                    <FontAwesomeIcon icon={faEnvelope}/> CEP
                                    </label>
<InputMask mask="99.999-999" type="text" class="form-control" id="cep" value={this.state.edit.cep}      onChange={(e)=>{

var value= e.target.value;

const cepRegex = /^\d{2}\.\d{3}-\d{3}$/;

this.setState((prevState) => ({
  edit: {
    ...prevState.edit, // Mantém os outros campos do objeto `edit`
    ['cep']: value, // Atualiza apenas o campo correspondente

  },
}));




if (cepRegex.test(value) || value === '') {
    e.target.disabled = true
    fetch(`${apiUrl}/buscar-cep/${value}`)
    .then(response => response.json())
    .then(data => {
        e.target.disabled = false
    

        this.setState((prevState) => ({
            edit: {
              ...prevState.edit, // Mantém os outros campos do objeto `edit`
              ['endereco']: data.logradouro, // Atualiza apenas o campo correspondente
              ['bairro']: data.bairro
            },
          }));



    })
    .catch(error => {
        e.target.disabled = false
    console.error('Erro:', error)});




}



                                    }}  required=""/>
                                </div>
                                <div class="col-md-6">
                                    <label for="endereco" class="form-label">
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/> Endereço
                                    </label>
                                    <input type="text" class="form-control" id="endereco"
                                        value={this.state.edit.endereco}      onChange={this.handleEdit} 
                                    
                                    required="" />
                                </div>
                           
</div>



<div class="row mb-3">
                                <div class="col-md-6">
                                    <label for="numero" class="form-label">
                                      <FontAwesomeIcon icon={faHome}/> Número
                                    </label>
                                    <input type="text" class="form-control" id="numero"
                                        value={this.state.edit.numero}      onChange={this.handleEdit} 
                                    required=""/>
                                </div>
                                <div class="col-md-6">
                                    <label for="bairro" class="form-label">
                                    <FontAwesomeIcon icon={faBuilding}/> Bairro
                                    </label>
                                    <input type="text" class="form-control" id="bairro"
                                    value={this.state.edit.bairro}    onChange={this.handleEdit} 
                                    required=""/>
                                </div>
                            </div>





</form>





    </div>

<div className='modal-fotter'>
<button className='btn btn-primary' onClick={(e)=>{
  var dados = this.state.edit;

  fetch(`${apiUrl}/atualizar-cliente`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // outras headers, se necessário
    },
    body: JSON.stringify(dados), // corpo da requisição em formato JSON
  })
    .then(response => {
        console.log(response)
if(response.status == 200){
    response.json().then(data=>{

var {id,nome,doc,email,telefone_celular} = this.state.edit;


var newItems  = {nome,doc,email,telefone_celular}

  this.setState((prevState) => ({
    
    items: prevState.items.map((item) =>
      
      item.id === id ? { ...item, ...newItems} : item
    ),
  }));
  

this.setState({edit:{}})
  
  toast.success("Cliente Atualizado")

    })
  
}
    }).catch(err=>{
      toast.error("Falha na comunicação com o servidor!")
    })
}}>
  <FontAwesomeIcon icon={faSave}/> Salvar
</button>
</div>



    </StaticDialog>





<StaticDialog
        isOpen={this.state.add}
        title="Cadastrar Cliente"
        onAfterClose={(result) => {
       
         this.setState({add:false})
        }}
    >
      
      <div className="container">


<form id="cadastroForm" onSubmit={(e)=>{
var dados = this.state.dados;
e.preventDefault();
console.log(dados)
}}>

<div class="mb-3 mt-3">
                                <div >
                                    <label for="nome" >
                                        <FontAwesomeIcon icon={faUser}/> Nome Completo (Responsável)
                                    </label>
                                    <input type="text" class="form-control mt-3" id="nome" 
                                    value={this.state.dados.nome || ""} 
              onChange={this.handleChange}  required=""/>
                                </div>
</div>



<div class="row">
                                <div class="col-md-6">
                                    <label for="tipo_pessoa" class="form-label">
                                     <FontAwesomeIcon icon={faClipboard}/> Tipo de Cliente
                                    </label>
                                    <select class="form-control" id="tipo_pessoa" required="" onChange={(e)=>{
var value = e.target.value;

if(value == "") return;


this.setState((prevState) => ({
    dados: {
      ...prevState.dados, // Mantém os outros campos do objeto `dados`
      ['tipo_pessoa']: value, // Atualiza apenas o campo correspondente
    },



  }));




                                    }}>
                                        <option value="" disabled="" selected="">Selecione...</option>
                                        <option value="fisica">Pessoa Física</option>
                                        <option value="juridica">Pessoa Jurídica</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="cnpj-cpf" class="form-label">
                                     <FontAwesomeIcon icon={faIdCard}/> CNPJ/CPF
                                    </label>
<input type="text" class="form-control" id="cnpj-cpf" required="" value={this.state.dados.doc}   onChange={(e)=>  this.handleCpfCnpjChange(e)}/>
                                </div>
</div>



<div class="row mt-3 mb-3">
                                <div class="col-md-6">
                                    <label for="inscricao-estadual" class="form-label">
                                
                                        <FontAwesomeIcon icon={faFileInvoice}/> Inscrição Estadual
                                    </label>
                                 <InputMask mask="999.999.999.999" type="text" class="form-control" id="inscricaoestadual"
                                    value={this.state.dados.inscricaoestadual}      onChange={this.handleChange} 
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="email" class="form-label">
                                    <FontAwesomeIcon icon={faEnvelope}/>  E-mail
                                    </label>
                                    <input type="email" class="form-control" id="email"   value={this.state.dados.email}      onChange={this.handleChange}  required=""/>
                                </div>
</div>



<div class="row mb-3">
                                <div class="col-md-6">
                                    <label for="telefone" class="form-label">
                                    <FontAwesomeIcon icon={faPhone}/> Telefone Fixo
                                    </label>
                                    <InputMask mask="99 9999-9999" type="tel" class="form-control" id="telefone"
                                    value={this.state.dados.telefone}      onChange={this.handleChange} 
                                    required=""/>
                                </div>


                                <div class="col-md-6">
                                    <label for="telefone-celular" class="form-label">
                                    <FontAwesomeIcon icon={faMobileAlt}/> Telefone Celular
                                    </label>
                                    <InputMask mask="99 9 9999-9999" type="tel" class="form-control" id="telefone_celular"
                                     value={this.state.dados.telefone_celular}      onChange={this.handleChange} 
                                    required=""/>
                                </div>
</div>



<div class="row mb-3">
<div class="col-md-6">
                                    <label for="cep" class="form-label">
                                    <FontAwesomeIcon icon={faEnvelope}/> CEP
                                    </label>
<InputMask mask="99.999-999" type="text" class="form-control" id="cep" value={this.state.dados.cep}      onChange={(e)=>{

var value= e.target.value;


this.setState((prevState) => ({
  dados: {
    ...prevState.dados, // Mantém os outros campos do objeto `dados`
    ['cep']: value // Atualiza apenas o campo correspondente

  },
}));


const cepRegex = /^\d{2}\.\d{3}-\d{3}$/;
if (cepRegex.test(value) || value === '') {
    e.target.disabled = true
    fetch(`${apiUrl}/buscar-cep/${value}`)
    .then(response => response.json())
    .then(data => {
        e.target.disabled = false
    

        this.setState((prevState) => ({
            dados: {
              ...prevState.dados, // Mantém os outros campos do objeto `dados`
              ['endereco']: data.logradouro, // Atualiza apenas o campo correspondente
              ['bairro']: data.bairro
            },
          }));



    })
    .catch(error => {
        e.target.disabled = false
    console.error('Erro:', error)});




}



                                    }}  required=""/>
                                </div>
                                <div class="col-md-6">
                                    <label for="endereco" class="form-label">
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/> Endereço
                                    </label>
                                    <input type="text" class="form-control" id="endereco"
                                        value={this.state.dados.endereco}      onChange={this.handleChange} 
                                    
                                    required="" />
                                </div>
                           
</div>



<div class="row mb-3">
                                <div class="col-md-6">
                                    <label for="numero" class="form-label">
                                      <FontAwesomeIcon icon={faHome}/> Número
                                    </label>
                                    <input type="text" class="form-control" id="numero"
                                        value={this.state.dados.numero}      onChange={this.handleChange} 
                                    required=""/>
                                </div>
                                <div class="col-md-6">
                                    <label for="bairro" class="form-label">
                                    <FontAwesomeIcon icon={faBuilding}/> Bairro
                                    </label>
                                    <input type="text" class="form-control" id="bairro"
                                    value={this.state.dados.bairro}    onChange={this.handleChange} 
                                    required=""/>
                                </div>
                            </div>





</form>





    </div>

    <div className='modal-fotter'>
    <button className='btn btn-primary' onClick={(e)=>{

var dados = this.state.dados;






  fetch(`${apiUrl}/cadastrar-cliente`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // outras headers, se necessário
    },
    body: JSON.stringify(dados), // corpo da requisição em formato JSON
  })
    .then(response => {
        console.log(response)
if(response.status == 200){
    response.json().then(data=>{

var {nome,tipo_pessoa,doc,inscricaoestadual,email,telefone,telefone_celular,cep,endereco,numero,bairro} = this.state.dados;

this.setState((prevState) => ({
items: [{id:data.id,nome,tipo_pessoa,doc,inscricaoestadual,email,doc,telefone,telefone_celular,cep,endereco,numero,bairro}, ...prevState.items],
    
}));


  this.setState({add:false,dados:{"nome":"","tipo_pessoa":"juridica","doc":"","inscricaoestadual":"","email":"","telefone":"","telefone_celular":"","cep":"","endereco":"","numero":"","bairro":""}})

  toast.success("Cliente adicionado")

    })
  
}
    }).catch(err=>{
      toast.error("Falha na comunicação com o servidor!")
    })




    }}>
        Salvar <FontAwesomeIcon icon={faSave}/>
    </button>
</div>
    </StaticDialog>
    </>
    );
  }
}

export default CadastroClientes;
