import React, { Component } from "react";
import { Link } from "react-router-dom";
import atencao from "../../../img/atencao.svg";
import logo from "../../../img/logo.png";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faClipboard,
  faCoffee,
  faEnvelope,
  faFileInvoice,
  faHome,
  faIdCard,
  faMapMarkerAlt,
  faMobileAlt,
  faPen,
  faPhone,
  faSave,
  faSearch,
  faTrash,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { StaticDialog, useDialog } from "react-st-modal";
import InputMask from "react-input-mask";
import { apiUrl } from "../../../comps/apiUrl";
import { toast } from "react-toastify";

class LeaseHistoy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      add: false,
      dados: {},
      items: [],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 15,
      edit: {},
      trash: {},
      comprovante: {},
    };
  }

  componentDidMount() {
    document.title = "Histórico de locações";
    this.fetchData(this.state.currentPage);
  }

  fetchData(page) {
    fetch(`${apiUrl}/leasers?page=${page}&limit=${this.state.itemsPerPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((data) => {
        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
        const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

        this.setState({
          items: data.items,
          currentPage: page,
          totalPages,
          loader: false,
        });
      });
    });
  }

  handlePageClick = (page) => {
    this.fetchData(page);
  };
  handleNextPage = () => {
    this.fetchData(this.state.currentPage + 1);
  };

  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.fetchData(this.state.currentPage - 1);
    }
  };

  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn-ligth"
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          &lt;
        </button>

        {pages}

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          className="btn btn-ligth"
          disabled={currentPage === totalPages}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    );
  }

  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handleEdit = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }
  handleCpfCnpjChange = (e) => {
    function cnpj(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
      v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
      return v;
    }

    function cpf(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      //de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
      return v;
    }

    if (e.target.value.length > 18) return;
    var value = e.target.value;
    if (e.target.value.length <= 14) {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cpf(value), // Atualiza apenas o campo correspondente
        },
      }));
    } else {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cnpj(value), // Atualiza apenas o campo correspondente
        },
      }));
    }
  };

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    return (
      <>
        <div class="content">
          <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between">
              <strong>Locações</strong>
            </div>
            <div class="card-body">
              {this.state.items.length > 0 ? (
                <>
                  <div class="mb-3 d-flex justify-content-center">
                    <div class="input-group w-50">
                      <input
                        type="text"
                        class="form-control"
                        id="searchInput"
                        onChange={(e) => {
                          this.setState({ search: e.target.value });
                        }}
                        placeholder="Pesquisar..."
                      />
                      <button
                        class="btn btn-primary"
                        onClick={(e) => {
                          console.log("aaaa");
                          e.target.classList.add("load");
                          var search = this.state.search;
                          fetch(`${apiUrl}/buscar-cliente?s=${search}`, {
                            method: "GET",
                            headers: {
                              "Content-Type": "application/json",
                            },
                          })
                            .then((response) => response.json())
                            .then((data) => {
                              e.target.classList.remove("load");
                            });
                        }}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                  <table class="table table-striped table-bordered arvore-l">
                    <thead class="thead-dark">
                      <tr>
                        <th>ID do Cliente</th>
                        <td>Nome do Cliente</td>
                        <th>Data da Locação</th>
                        <th>Data da Entrega</th>
                        <th>Equipamento</th>
                        <th>Valor Unitário</th>
                        <th>Quantidade</th>
                        <th>Dias de Locação</th>
                        <th>Valor Total</th>
                        <th>Devolução</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.items.map((item, index) => (
                        <>
                          <tr>
                            <td>{item.id_cliente}</td>
                            <td>{item.n_cliente}</td>
                            <td>{this.formatDate(item.startDate)}</td>
                            <td>{this.formatDate(item.endDate)}</td>
                            <td>{item.machine}</td>
                            <td>
                              {item.price.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>
                            <td>{item.quanty}</td>
                            <td>{item.days}</td>
                            <td>
                              {item.total.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>
                            <td>
                              <button
                                className="btn btn-success"
                                onClick={(e) => {
                                  this.setState({ comprovante: item });
                                }}
                              >
                                Gerar comprovante
                              </button>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>Não há resultados</>
              )}
            </div>

            <div
              className="card-footer"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {this.renderPagination()}
            </div>
          </div>
        </div>

        <StaticDialog
          isOpen={Object.keys(this.state.comprovante).length > 0}
          title="Informações de Devolução"
          onAfterClose={(result) => {
            this.setState({ comprovante: {} });
          }}
        >
          <div className="content">
            <div class="mb-3">
              <label for="nome-cliente" class="form-label">
                Nome do Cliente
              </label>
              <input
                type="text"
                id="nome-cliente"
                class="form-control"
                value={this.state.comprovante.n_cliente}
                disabled
              />
            </div>
            <div>
              <textarea
                class="form-control"
                placeholder="Observação"
                style={{ height: "100px" }}
              />
            </div>
          </div>
          <div className="modal-fotter">
            <button className="btn btn-primary">
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default LeaseHistoy;
