import {
  faEye,
  faMinus,
  faPen,
  faPlus,
  faSave,
  faTrash,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { apiUrl } from "../../comps/apiUrl";
import { toast } from "react-toastify";
import { StaticDialog } from "react-st-modal";
import InputMask from "react-input-mask";
class Lease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      showModal: false,
      data: [],
      dados: { quantidade: 1, price: 0 },

      busca: "",
      rBusca: [],

      BuscaM: "",
      rBuscaM: [],

      products: [],
      newProduct: false,
    };
  }

  componentDidMount() {
    document.title = "Locação";
    fetch(`${apiUrl}/leaser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        response.json().then((data) => {
          this.setState({
            data,
            loader: false,
          });
        });
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao carregar as informações");
      });
  }

  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }
  handleStartDateChange = (event) => {
    this.setState({ startDate: event.target.value }, this.calculateDifference);
  };

  handleEndDateChange = (event) => {
    this.setState({ endDate: event.target.value }, this.calculateDifference);
  };

  calculateDifference = () => {
    const { startDate, endDate } = this.state;

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      if (
        start instanceof Date &&
        end instanceof Date &&
        !isNaN(start) &&
        !isNaN(end)
      ) {
        const differenceInTime = end.getTime() - start.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);

        this.setState({
          dayDifference: Math.max(0, Math.floor(differenceInDays)),
        });
      } else {
        this.setState({ dayDifference: null });
      }
    } else {
      this.setState({ dayDifference: null });
    }
  };

  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  render() {
    if (this.state.loader) {
      return (
        <div className="area-loader">
          <div className="loader"></div>
        </div>
      );
    }
    var { dayDifference } = this.state;
    return (
      <div className="content">
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <strong>Locação de Equipamentos</strong>
          </div>
          <div className="card-body">
            {this.state.dados.cliente == undefined ? (
              <>
                <button
                  type="button"
                  class="btn btn-primary mb-2"
                  onClick={() => this.setState({ showModal: true })}
                >
                  <FontAwesomeIcon icon={faUserPlus} /> Adicionar cliente
                </button>
              </>
            ) : (
              <>
                <label>Cliente</label>
                <table class="table table-striped table-bordered arvore-l mt-2">
                  <thead class="thead-dark">
                    <tr>
                      <th>Nome</th>
                      <th>Documento</th>
                      <th>Opção</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{this.state.dados.cliente.nome}</td>
                      <td>{this.state.dados.cliente.doc}</td>
                      <td>
                        <button
                          class="btn btn-danger"
                          onClick={(e) => {
                            this.setState((prevState) => {
                              const { cliente, ...rest } = prevState.dados; // Desestrutura e exclui "cliente"
                              return {
                                dados: rest, // Atualiza "dados" com o restante das propriedades
                              };
                            });
                          }}
                        >
                          Excluir
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            <div className="card mt-2 mb-2">
              <div className="card-header d-flex justify-content-between">
                <h3>Produtos</h3>
              </div>
              <div className="card-body">
                {this.state.products.length > 0 ? (
                  <>
                    <table class="table table-striped table-bordered arvore-l">
                      <thead class="thead-dark">
                        <tr>
                          <th>ID</th>
                          <th scope="col">Nome</th>
                          <th scope="col">Preço</th>
                          <th>Quantidade</th>
                          <th scope="col">Opção</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.products.map((product) => (
                          <tr>
                            <td>RBM-{product.id}</td>
                            <td>{product.name}</td>
                            <td>
                              {product.price.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>
                            <td style={{ width: 200 }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    var id = product.id;
                                    this.setState((prevState) => {
                                      const novosItens = prevState.products.map(
                                        (item) => {
                                          if (item.id === id) {
                                            // Verifica se a quantidade é maior que 1 antes de diminuir
                                            const novaQuantidade =
                                              item.quantity > 1
                                                ? item.quantity - 1
                                                : 1;
                                            return {
                                              ...item,
                                              quantity: novaQuantidade, // Atualiza a quantidade
                                            };
                                          }
                                          return item;
                                        }
                                      );

                                      return { products: novosItens };
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faMinus} />
                                </button>
                                <input
                                  type="text"
                                  style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    width: 50,
                                    textAlign: "center",
                                  }}
                                  value={product.quantity}
                                  className="form-control"
                                  disabled
                                />
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    var id = product.id;
                                    this.setState((prevState) => {
                                      const novosItens = prevState.products.map(
                                        (item) => {
                                          if (item.id === id) {
                                            return {
                                              ...item,
                                              quantity: item.quantity + 1, // Incrementa a quantidade
                                            };
                                          }
                                          return item;
                                        }
                                      );

                                      return { products: novosItens };
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </button>
                              </div>
                            </td>
                            <td>
                              <button
                                class="btn btn-danger"
                                onClick={(e) => {
                                  var id = product.id;
                                  this.setState((prevState) => {
                                    // Filtra o array de itens, removendo o item pelo id
                                    const novosItens =
                                      prevState.products.filter(
                                        (item) => item.id !== id
                                      );

                                    return { products: novosItens }; // Atualiza o estado com o novo array de itens
                                  });
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => this.setState({ newProduct: true })}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Adicionar máquina
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.setState({ newProduct: true })}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Adicionar produto
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <label for="nome-cliente" class="form-label">
                  Vendedor
                </label>
                <select
                  class="form-control"
                  id="vendedor"
                  onChange={(e) => this.handleChange(e)}
                >
                  <option value="">Selecione o Vendedor</option>
                  {this.state.data.sellers.map((vendedor) => (
                    <option value={vendedor.id}>{vendedor.name}</option>
                  ))}
                </select>
              </div>
              <div class="col-md-6">
                <label for="loja" class="form-label">
                  Loja
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={this.state.data.store.nome}
                  disabled
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-6">
                <label for="data-inicial" class="form-label">
                  Data Inicial
                </label>
                <input
                  type="date"
                  id="data-inicial"
                  class="form-control"
                  onChange={this.handleStartDateChange}
                  required=""
                />
              </div>
              <div class="col-md-6">
                <label for="data-final" class="form-label">
                  Data Final
                </label>
                <input
                  type="date"
                  id="data-final"
                  class="form-control"
                  onChange={this.handleEndDateChange}
                  required=""
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-3">
                <label for="dias-locacao" class="form-label">
                  Multa por Atraso
                </label>
                <input
                  value={this.state.dados.price}
                  id="dias-locacao"
                  class="form-control"
                />
              </div>
              <div class="col-md-3">
                <label for="dias-locacao" class="form-label">
                  Frete
                </label>
                <input
                  value={this.state.dados.price}
                  id="dias-locacao"
                  class="form-control"
                />
              </div>
            </div>

            {dayDifference != null && (
              <>
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="dias-locacao" class="form-label">
                      Dias de Locação
                    </label>
                    <input
                      value={dayDifference != null ? dayDifference : ""}
                      id="dias-locacao"
                      class="form-control"
                      disabled="true"
                    />
                  </div>

                  <div class="col-md-3">
                    <label for="valor-total" class="form-label">
                      Valor Total
                    </label>
                    <input
                      type="text"
                      id="valor-total"
                      value={(
                        dayDifference *
                        this.state.dados.quantidade *
                        this.state.dados.price
                      ).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                      class="form-control"
                      disabled="true"
                    />
                  </div>

                  <div class="col-md-3">
                    <label for="forma-pagamento" class="form-label">
                      Forma de Pagamento
                    </label>
                    <select
                      id="tpay"
                      class="form-control"
                      onChange={(e) => this.handleChange(e)}
                      required=""
                    >
                      <option value="" disabled="" selected="">
                        Selecione
                      </option>
                      <option value="cartao">Cartão de Crédito</option>
                      <option value="cartao">Cartão de Débito</option>
                      <option value="dinheiro">Dinheiro</option>
                      <option value="pix">PIX</option>
                      <option value="cheque">Cheque</option>
                    </select>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="card-footer">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  console.log(this.state.dados);
                  if (this.state.dados.cliente == undefined) {
                    return toast.warn("Selecione um cliente");
                  }
                  if (this.state.dados.vendedor == undefined) {
                    return toast.warn("Selecione um vendedor");
                  }
                  if (this.state.dados.machine == undefined) {
                    return toast.warn("Selecione um equipamento");
                  }
                  if (this.state.startDate == undefined) {
                    return toast.warn("Selecione a data inicial");
                  }
                  if (this.state.endDate == undefined) {
                    return toast.warn("Selecione a data final");
                  }
                  if (this.state.dados.tpay == undefined) {
                    return toast.warn("Selecione uma forma de pagamento");
                  }
                  fetch(`${apiUrl}/new-lease`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      authorization: localStorage.getItem("token"),
                    },
                    body: JSON.stringify({
                      cliente: this.state.dados.cliente,
                      vendedor: this.state.dados.vendedor,
                      machine: this.state.dados.machine,
                      price: this.state.dados.price,
                      quantidade: this.state.dados.quantidade,
                      startDate: this.state.startDate,
                      endDate: this.state.endDate,
                      tpay: this.state.dados.tpay,
                      days: dayDifference,
                      total:
                        dayDifference *
                        (this.state.dados.quantidade * this.state.dados.price),
                    }),
                  })
                    .then((res) => {
                      if (res.status == 200) {
                        res.json().then((data) => {
                          toast.success("Locação adicionada com sucesso");
                          this.props.navigate(`/lease/view/${data.token}`);
                        });
                      } else {
                        toast.error("Ocorreu um erro ao adicionar a locação");
                      }
                    })
                    .catch((error) => {
                      toast.error("Ocorreu um erro ao adicionar a locação");
                      console.error(error);
                    });
                }}
              >
                <FontAwesomeIcon icon={faSave} /> Salvar
              </button>
            </div>
          </div>
        </div>

        <StaticDialog
          isOpen={this.state.newProduct}
          title="Buscar Máquina"
          onAfterClose={(result) => {
            this.setState({ newProduct: false, rBuscaM: [], BuscaM: "" });
          }}
        >
          <div className="content">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (this.state.BuscaM.trim() == "") {
                  toast.warn("Digite algo para buscar");
                  e.target.classList.remove("load");
                  return;
                }
                fetch(`${apiUrl}/buscar-maquina?s=${this.state.BuscaM}`, {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                  .then((res) => {
                    e.target.classList.remove("load");
                    if (res.status === 404) {
                      this.setState({ rBusca: null });

                      return null;
                    }
                    if (!res.ok) {
                      throw new Error("Erro ao buscar os dados");
                    }
                    return res.json();
                  })
                  .then((data) => {
                    if (data) {
                      this.setState({ rBuscaM: data });
                    }
                  })
                  .catch((error) => {
                    e.target.classList.remove("load");
                    toast.error("Ocorreu um erro ao buscar o cliente");
                    console.error(error);
                  });
              }}
            >
              <div className="input-group">
                <input
                  placeholder="Digite..."
                  onChange={(e) => this.setState({ BuscaM: e.target.value })}
                  value={this.state.BuscaM || ""}
                  className="form-control"
                />
                <button type="submit" className="btn btn-primary">
                  Buscar
                </button>
              </div>
            </form>

            <div className="mt-3">
              {this.state.rBuscaM ? (
                this.state.rBuscaM.length > 0 && (
                  <>
                    <table class="table table-striped table-bordered arvore-l">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Nome</th>
                          <th scope="col">Preço</th>
                          <th scope="col">Opção</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rBuscaM.length > 0 &&
                          this.state.rBuscaM.map((item, index) => (
                            <>
                              <tr>
                                <td>RBM-{item.id}</td>
                                <td>{item.machineName}</td>
                                <td>
                                  {item.price.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                      this.setState({
                                        products: [
                                          ...this.state.products,
                                          {
                                            id: item.id,
                                            name: item.machineName,
                                            quantity: 1,
                                            price: item.price,
                                          },
                                        ],
                                        newProduct: false,
                                        rBuscaM: [],
                                        BuscaM: "",
                                      });
                                    }}
                                  >
                                    Selecionar
                                  </button>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </>
                )
              ) : (
                <div className="text-center">Nem um resultado encontrado</div>
              )}
            </div>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.showModal}
          title="Buscar cliente"
          onAfterClose={(result) => {
            this.setState({ showModal: false, rBusca: [], busca: "" });
          }}
        >
          <div className="content">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (this.state.busca.trim() == "") {
                  toast.warn("Digite algo para buscar");
                  e.target.classList.remove("load");
                  return;
                }
                fetch(`${apiUrl}/buscar-cliente?s=${this.state.busca}`, {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                  .then((res) => {
                    e.target.classList.remove("load");
                    if (res.status === 404) {
                      this.setState({ rBusca: null });

                      return null;
                    }
                    if (!res.ok) {
                      throw new Error("Erro ao buscar os dados");
                    }
                    return res.json();
                  })
                  .then((data) => {
                    if (data) {
                      this.setState({ rBusca: data });
                    }
                  })
                  .catch((error) => {
                    e.target.classList.remove("load");
                    toast.error("Ocorreu um erro ao buscar o cliente");
                    console.error(error);
                  });
              }}
            >
              <div className="input-group">
                <select
                  className="form-control"
                  onChange={(e) => {
                    var value = e.target.value;
                    switch (value) {
                      case "1":
                        this.setState({ mask: "" });
                        break;
                      case "2":
                        this.setState({ mask: "999.999.999-99" });
                        break;
                      case "3":
                        this.setState({ mask: "99.999.999/9999-99" });
                        break;
                      case "4":
                        this.setState({ mask: "" });
                        break;
                      case "5":
                        this.setState({ mask: "(99) 9999-9999" });
                        break;
                      case "6":
                        this.setState({ mask: "(99) 9 9999-9999" });
                        break;
                    }
                  }}
                >
                  <option value="1">Nome</option>
                  <option value="2">CPF</option>
                  <option value="3">CNPJ</option>
                  <option value="4">Email</option>
                  <option value="5">Telefone</option>
                  <option value="6">Celular</option>
                </select>
                <InputMask
                  mask={this.state.mask}
                  placeholder="Digite..."
                  onChange={(e) => this.setState({ busca: e.target.value })}
                  value={this.state.busca || ""}
                  className="form-control"
                />
                <button type="submit" className="btn btn-primary">
                  Buscar
                </button>
              </div>
            </form>

            <div className="mt-3">
              {this.state.rBusca ? (
                this.state.rBusca.length > 0 && (
                  <>
                    <table class="table table-striped table-bordered arvore-l">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Nome</th>
                          <th scope="col">Documento</th>
                          <th scope="col">Opção</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rBusca.length > 0 &&
                          this.state.rBusca.map((item, index) => (
                            <>
                              <tr>
                                <td>{item.nome}</td>
                                <td>{item.doc}</td>
                                <td>
                                  <button
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                      this.setState((prevState) => ({
                                        dados: {
                                          ...prevState.dados, // Mantém os dados existentes
                                          cliente: {
                                            id: item.id,
                                            nome: item.nome,
                                            doc: item.doc,
                                          }, // Adiciona a nova propriedade cliente
                                        },
                                        showModal: false,
                                        rBusca: [],
                                        busca: "",
                                      }));
                                    }}
                                  >
                                    Selecionar
                                  </button>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </>
                )
              ) : (
                <div className="text-center">Nem um resultado encontrado</div>
              )}
            </div>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default Lease;
