import { faPercent, faSave, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../../comps/apiUrl';
import { toast } from 'react-toastify';
import { StaticDialog } from 'react-st-modal';
import InputMask from "react-input-mask";
class NewBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader:true,
      maquinas: [],
      dados: {quantidade: 1,price:0},
      inputVisivel: false,
      showModal: false,
      mask:'',
      busca:'',
      rBusca:[],
    };
  }

  componentDidMount(){
    document.title = "Novo orçamento"
  

    fetch(`${apiUrl}/listar-maquinas`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json())
      .then((data) => {
        this.setState({
          maquinas: data,
          loader:false
        });
      }).catch((error) => {
     toast.error("Ocorreu um erro ao carregar as maquinas")
      });



  }


  Cdias(dataInicial, dataFinal) {
    // Converter as datas para objetos Date
    const inicio = new Date(dataInicial);
    const fim = new Date(dataFinal);

    // Calcular a diferença em milissegundos
    const diferencaEmMilissegundos = fim - inicio;

    // Converter milissegundos para dias (1 dia = 24h * 60m * 60s * 1000ms)
    const milissegundosPorDia = 24 * 60 * 60 * 1000;
    const diferencaEmDias = diferencaEmMilissegundos / milissegundosPorDia;

    // Retorna o número inteiro de dias
    this.setState({dias:diferencaEmDias})
    return Math.round(diferencaEmDias);
}



  validarData(dateString) {
   
        // Regex para verificar o formato estrito YYYY-MM-DD
        const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

        if (!regex.test(dateString)) {
            return false; // Formato inválido
        }
    
        // Converte a string para uma data
        const inputDate = new Date(dateString);
    
        // Verifica se a data é inválida
        if (isNaN(inputDate.getTime())) {
            return false;
        }
    
        // Pega a data de hoje e 1 ano no futuro
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Zera horas para comparar apenas datas
        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(today.getFullYear() + 1);
    
        // Verifica se a data está no intervalo
        return inputDate >= today && inputDate <= oneYearFromNow;
    
  }


 
  calcularTotal = () => {
 
    var valorUnitario = this.state.dados.price;
    var quantidade = this.state.dados.quantidade;
  

    // Remove "R$" e converte para número
    const valorNumerico = parseFloat(
      valorUnitario.replace('R$', '').replace('.', '').replace(',', '.')
    );

    // Multiplica pelo número de itens
    const soma = valorNumerico * quantidade;

    // Formata o resultado como moeda BRL
   return  soma.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

  };





  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };


  handleStartDateChange = (event) => {
    this.setState({ startDate: event.target.value }, this.calculateDifference);
  };

  handleEndDateChange = (event) => {
    this.setState({ endDate: event.target.value }, this.calculateDifference);
  };

  calculateDifference = () => {
    const { startDate, endDate } = this.state;

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      if (start instanceof Date && end instanceof Date && !isNaN(start) && !isNaN(end)) {
        const differenceInTime = end.getTime() - start.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);

        this.setState({ dayDifference: Math.max(0, Math.floor(differenceInDays)) });
      } else {
        this.setState({ dayDifference: null });
      }
    } else {
      this.setState({ dayDifference: null });
    }
  };



  render() {

    if(this.state.loader){
        return(<div class="area-loader">
    <div class="loader"></div>
        </div>)
    }
    const { startDate, endDate, dayDifference } = this.state;
    return (<div className='content'>


<div className='card'>
<div className='card-header d-flex'>
<strong>Novo orçamento</strong>
</div>
<div className='card-body'>


<div id="orcamento">

{this.state.dados.cliente == undefined ? <><button type="button" class="btn btn-primary mb-2" onClick={() => this.setState({showModal: true})}>
  <FontAwesomeIcon icon={faUserPlus}/> Adicionar cliente
</button></>:<>

<label>Cliente</label>
<table class="table table-striped table-bordered arvore-l mt-2">
      <thead class="thead-dark">
        <tr>
          <th>Nome</th>
          <th>Documento</th>
          <th>Opção</th>

        </tr>
      </thead>
      <tbody>
        <tr>
        <td>{this.state.dados.cliente.nome}</td>
        <td>{this.state.dados.cliente.doc}</td>
        <td><button class="btn btn-danger" onClick={(e)=>{
          this.setState(prevState => {
            const { cliente, ...rest } = prevState.dados; // Desestrutura e exclui "cliente"
            return {
              dados: rest // Atualiza "dados" com o restante das propriedades
            };
          });
          
        }}>Excluir</button></td>
        </tr>
      </tbody>
      </table>



</>}

        
            <div class="row mb-3">
                <div class="col-md-6">
                    <label for="equipamento" class="form-label">Equipamento:</label>
         
                    <select id="equipamento" class="form-select" name="equipamento" onChange={(e) =>{
                        if(e.target.value == "") return;
                        var item  = this.state.maquinas.find((item) => item.id == e.target.value);
                         
                        this.setState((prevState) => ({
                          dados: {
                            ...prevState.dados, // Mantém os outros campos do objeto `dados`
                            ['machineName']:item.machineName, // Atualiza apenas o campo correspondente
                            ['price']:item.price
                          },
                        }));


                    }} required="">
                    <option value="">Selecione um equipamento</option>
                       {this.state.maquinas.map((item) => (
                        <>
                    
                        <option value={item.id}>{item.machineName}</option>
                        </>
                      ))}
                    </select>
                </div>
                <div class="col-md-3">
                    <label for="quantidade" class="form-label">Valor unitário:</label>
                    <input type="text" id="quantidade" class="form-control" value={this.state.dados.price.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })}  disabled/>
                </div>
                <div class="col-md-3">
                    <label for="quantidade" class="form-label">Quantidade:</label>
                    <input type="number" id="quantidade" class="form-control" name="quantidade" onChange={(e) => this.handleChange(e)} value={this.state.dados.quantidade} required=""/>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                    <label for="data-inicial" class="form-label">Data Inicial</label>
  <input type="date" id="data_inicial" class="form-control"   onChange={this.handleStartDateChange} required=""/>
                </div>
                <div class="col-md-6">
                    <label for="data-final" class="form-label">Data Final</label>
                    <input type="date" id="data_final" class="form-control"  onChange={this.handleEndDateChange} required=""/>
                </div>
            </div>


{dayDifference != null && <>


  <div class="row mb-3">
                <div class="col-md-6">
                    <label for="data-inicial" class="form-label">Quantidade de dias</label>
                    <input type="text" id="data_inicial" class="form-control" value={dayDifference != null ? dayDifference : ''} disabled/>
                </div>
                <div class="col-md-6">
                    <label for="data-final" class="form-label">Valor</label>
                    <input type="text" id="data_final" class="form-control" value={dayDifference * (this.state.dados.quantidade * this.state.dados.price) } disabled/>
                </div>
            </div>

         


</>}




       


   
    </div>


</div>
<div className='card-footer'>
     <div style={{display:'flex',justifyContent:'flex-end'}}>
     <button type="submit" class="btn btn-primary me-2" onClick={(e)=>{
      e.target.classList.add('load')

      
      fetch(`${apiUrl}/new-budget`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // outras headers, se necessário
        },
        body: JSON.stringify({
        cliente:this.state.dados.cliente,
        machineName: this.state.dados.machineName,
        price: this.state.dados.price,
        quantidade: this.state.dados.quantidade,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        days: dayDifference,
        total: dayDifference * (this.state.dados.quantidade * this.state.dados.price)
        }), // corpo da requisição em formato JSON
      })
        .then(response => {
          if(response.ok){
            response.json().then(data => {
              toast.success("Orçamento criado com sucesso")
              this.props.navigate('/budget/view/'+data.token)  
            })
          }
        e.target.classList.remove('load')
        }).catch(error => {
          toast.error("Falha de comunicação com o servidor")
        })
     }}><FontAwesomeIcon icon={faSave}/> Salvar Orçamento</button>
               
     </div>
                
 </div>
      
</div>

<StaticDialog
        isOpen={this.state.showModal}
        title="Buscar cliente"
        
        onAfterClose={(result) => {
       
        this.setState({showModal: false,rBusca:[],busca:''});
        }}
    >
      <div className='content'>
<div className='input-group'>
<select className='form-control' onChange={(e)=>{
  var value = e.target.value;
  switch(value){
    case '1':
      this.setState({mask:''})
      break;
    case '2':
    this.setState({mask:'999.999.999-99'})
      break;
    case '3':
    this.setState({mask:'99.999.999/9999-99'})
      break;
    case '4':
      this.setState({mask:''})
      break;
    case '5':
      this.setState({mask:'(99) 9999-9999'})
      break;
    case '6':
      this.setState({mask:'(99) 9 9999-9999'})
      break;
  }
}}>
  <option value="1">Nome</option>
  <option value="2">CPF</option>
  <option value="3">CNPJ</option>
  <option value="4">Email</option>
  <option value="5">Telefone</option>
  <option value="6">Celular</option>
</select>
<InputMask mask={this.state.mask} placeholder='Digite...' onChange={(e) => this.setState({busca: e.target.value})} value={this.state.busca || '' } className='form-control'/>
<button className='btn btn-primary' onClick={(e) => {
        e.target.classList.add('load');
        if (this.state.busca.trim() === '') {
          toast.warn("Digite algo para buscar");
          e.target.classList.remove('load');
          return;
        }
        fetch(`${apiUrl}/buscar-cliente?s=${this.state.busca}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(res => {
          e.target.classList.remove('load');
          if (res.status === 404) {
            this.setState({ rBusca: null });
            
            return null;
          }
          if (!res.ok) {
            throw new Error("Erro ao buscar os dados");
          }
          return res.json();
        })
        .then(data => {
          if (data) {
            this.setState({ rBusca: data });
          }
        })
        .catch((error) => {
          e.target.classList.remove('load');
          toast.error("Ocorreu um erro ao buscar o cliente");
          console.error(error);
        });
      }}>Buscar</button>
</div>
<div className='mt-3'>
{this.state.rBusca 
      ? 
      
      this.state.rBusca.length > 0 && <>
      <table class="table table-striped table-bordered arvore-l">
      <thead class="thead-dark">
        <tr>

          <th scope="col">Nome</th>
          <th scope="col">Documento</th>
          <th scope="col">Opção</th>
        </tr>
      </thead>
      <tbody>
      {this.state.rBusca.length > 0 && this.state.rBusca.map((item, index) =><>
     
      <tr>
      <td>{item.nome}</td>
      <td>{item.doc}</td>
      <td><button className='btn btn-primary' onClick={(e)=>{
this.setState(prevState => ({
  dados: {
    ...prevState.dados, // Mantém os dados existentes
    cliente:{id:item.id,nome:item.nome,doc:item.doc},        // Adiciona a nova propriedade cliente
  },
  showModal: false,
  rBusca:[],
  busca:'',
}));
      }}>Selecionar</button></td>
  </tr>
      </>)}
      </tbody>
      </table>
      </>
    
      
      
      
      
        

      : <div className='text-center'>Nem um resultado encontrado</div>}
</div>
      </div>
</StaticDialog>

    </div>
      
    );
  }
}

export default NewBudget;