import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../../img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faChartLine,
  faClipboard,
  faClock,
  faDollar,
  faMoneyBill,
  faPeopleArrows,
  faPeopleGroup,
  faScrewdriver,
  faScrewdriverWrench,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { StaticDialog } from "react-st-modal";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logout: false,
    };
  }

  getClassNames = ({ isActive }) => {
    return isActive && "active";
  };

  render() {
    return (
      <>
        <nav class="navbar navbar-expand-lg navbar-dark">
          <div class="container-fluid container-custom">
            <Link class="navbar-brand" to="/">
              <img src={logo} alt="Logo" class="logo" />
            </Link>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item dropdown">
                  <Link
                    class="nav-link dropdown-toggle"
                    to="perfil"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fas fa-user"></i>
                    Perfil
                  </Link>
                  <ul
                    class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link class="dropdown-item" to="#">
                        Configurações
                      </Link>
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <Link
                    class="nav-link"
                    onClick={() => {
                      this.setState({ logout: true });
                    }}
                  >
                    <FontAwesomeIcon icon={faSignOut} /> Sair
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div class="sidebar">
          <div class="espaco">
            <NavLink to="/" className={this.getClassNames}>
              <i class="fas fa-clipboard"></i>
              Locação
            </NavLink>
            <NavLink to="/budget" className={this.getClassNames}>
              <FontAwesomeIcon icon={faClipboard} /> Orçamento
            </NavLink>
            <NavLink to="/lease-history" className={this.getClassNames}>
              <FontAwesomeIcon icon={faClock} /> Histórico de Locações
            </NavLink>
            <NavLink to="cadastro-clientes" className={this.getClassNames}>
              <FontAwesomeIcon icon={faPeopleGroup} /> Cadastro de Clientes
            </NavLink>

            <NavLink to="cadastro-maquinas" className={this.getClassNames}>
              <FontAwesomeIcon icon={faScrewdriverWrench} /> Cadastro de
              máquinas
            </NavLink>
            <NavLink to="/services" className={this.getClassNames}>
              <FontAwesomeIcon icon={faBriefcase} /> Serviços
            </NavLink>
            <NavLink to="financeiro" className={this.getClassNames}>
              <FontAwesomeIcon icon={faDollar} /> Financeiro
            </NavLink>
            <NavLink to="relatorios" className={this.getClassNames}>
              <FontAwesomeIcon icon={faChartLine} /> Relatórios
            </NavLink>
          </div>
        </div>

        <StaticDialog
          isOpen={this.state.logout}
          title="Atenção"
          onAfterClose={(result) => {
            this.setState({ logout: false });
          }}
        >
          <div style={{ margin: 10 }}>
            Você tem certeza de que deseja encerrar a sessão? Essa ação irá
            desconectá-lo da sua conta.
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-danger"
              onClick={() => {
                window.localStorage.removeItem("token");
                window.location.href = "/";
              }}
            >
              <FontAwesomeIcon icon={faSignOut} /> Sair
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default Header;
