import {
  faEye,
  faPen,
  faTrash,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { apiUrl } from "../../comps/apiUrl";
import { toast } from "react-toastify";

class Budget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      budget: 0,
      expenses: [],
      items: [],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 15,
    };
  }

  componentDidMount() {
    document.title = "Orçamento";
    this.fetchData(this.state.currentPage);
  }

  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }

  fetchData(page) {
    fetch(
      `${apiUrl}/list-budget?page=${page}&limit=${this.state.itemsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      response.json().then((data) => {
        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
        const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

        this.setState({
          items: data.items,
          currentPage: page,
          totalPages,
          loader: false,
        });
      });
    });
  }

  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn-ligth"
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          &lt;
        </button>

        {pages}

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          className="btn btn-ligth"
          disabled={currentPage === totalPages}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    );
  }

  render() {
    if (this.state.loader) {
      return (
        <div className="area-loader">
          <div className="loader"></div>
        </div>
      );
    }

    return (
      <div className="content">
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <strong>Orçamentos</strong>
            <Link
              to="/budget/new"
              class="btn btn-primary"
              onClick={() => {
                this.setState({ add: true });
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faUserPlus} /> Novo Orçamento
            </Link>
          </div>
          <div className="card-body">
            {this.state.items.length > 0 ? (
              <>
                <table class="table table-striped table-bordered arvore-l">
                  <thead class="thead-dark">
                    <tr>
                      <th>Nome do Cliente</th>
                      <th>Documento</th>

                      <th>Equipamento</th>
                      <th>Valor Unitário</th>
                      <th>Período de Locação</th>
                      <th>Valor Total</th>
                      <th>Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map((item, index) => (
                      <>
                        <tr>
                          <td>{item.nome || "Não informado"}</td>
                          <td>{item.doc || "Não informado"}</td>

                          <td>
                            {item.quantidade}X {item.machineName}
                          </td>
                          <td>
                            {Number(item.price).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </td>
                          <td>
                            {this.formatDate(item.startDate)} a{" "}
                            {this.formatDate(item.endDate)}
                          </td>
                          <td>
                            {Number(item.total).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </td>
                          <td className="btn-group">
                            <Link
                              to={`/budget/view/${item.token}`}
                              className="btn btn-success"
                              onClick={() => {}}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </Link>

                            <Link
                              to={`/budget/edit/${item.token}`}
                              className="btn btn-primary"
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </Link>
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                this.setState({ trash: item });
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>Não há resultados</>
            )}
          </div>
          <div
            className="card-footer"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {this.renderPagination()}
          </div>
        </div>
      </div>
    );
  }
}

export default Budget;
