import {
  faPen,
  faSave,
  faSearch,
  faTrash,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { StaticDialog } from "react-st-modal";
import { apiUrl } from "../../comps/apiUrl";
import { toast } from "react-toastify";

class CadastroMaquina extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      add: false,
      dados: { price: "R$0,00" },
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 15,
      loader: true,
      edit: {},
      trash: {},
    };
  }

  componentDidMount() {
    document.title = "Cadastro de Maquinas";
    this.fetchData(this.state.currentPage);
  }

  formatarMoeda = (valor) => {
    // Remove caracteres que não sejam números
    const apenasNumeros = valor.replace(/\D/g, "");
    // Converte para número e formata em BRL
    const valorFormatado = (Number(apenasNumeros) / 100).toLocaleString(
      "pt-BR",
      {
        style: "currency",
        currency: "BRL",
      }
    );
    return valorFormatado;
  };

  fetchData(page) {
    fetch(`${apiUrl}/maquinas?page=${page}&limit=${this.state.itemsPerPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((data) => {
        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
        const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

        this.setState({
          items: data.items,
          currentPage: page,
          totalPages,
          loader: false,
        });
      });
    });
  }

  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn-ligth"
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          &lt;
        </button>

        {pages}

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          className="btn btn-ligth"
          disabled={currentPage === totalPages}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    );
  }

  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  handleEdit = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    return (
      <div className="content">
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <strong>Cadastro de Maquinas</strong>
            <button
              class="btn btn-primary"
              onClick={() => {
                this.setState({ add: true });
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faUserPlus} /> Cadastrar Maquina
            </button>
          </div>
          <div className="card-body">
            {this.state.items.length > 0 ? (
              <>
                <div class="mb-3 d-flex justify-content-center">
                  <div class="input-group w-50">
                    <input
                      type="text"
                      class="form-control"
                      id="searchInput"
                      onChange={(e) => {
                        this.setState({ search: e.target.value });
                      }}
                      placeholder="Pesquisar..."
                    />
                    <button
                      class="btn btn-primary"
                      onClick={(e) => {
                        console.log("aaaa");
                        e.target.classList.add("load");
                        var search = this.state.search;
                        fetch(`${apiUrl}/buscar-cliente?s=${search}`, {
                          method: "GET",
                          headers: {
                            "Content-Type": "application/json",
                          },
                        })
                          .then((response) => response.json())
                          .then((data) => {
                            e.target.classList.remove("load");
                          });
                      }}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </div>
                </div>
                <table class="table table-striped table-bordered arvore-l">
                  <thead class="thead-dark">
                    <tr>
                      <th>ID</th>
                      <th>Nome do Equipamento</th>
                      <th>Estado</th>
                      <th>Preço</th>
                      <th>Data de Aquisição</th>
                      <th>Data de Manutenção</th>
                      <th>Qtd Estoque</th>
                      <th>Qtd Locada</th>
                      <th>Qtd Total</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map((item, index) => (
                      <>
                        <tr>
                          <td>{item.id}</td>
                          <td>{item.machineName}</td>
                          <td>{item.machineState}</td>
                          <td>
                            {item.price.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </td>
                          <td>{item.acquisitionDate}</td>
                          <td>{item.maintenanceDate}</td>
                          <td>{item.quantityStock}</td>
                          <td>{item.quantityBorrowed}</td>
                          <td>{item.quantityTotal}</td>
                          <td className="btns">
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                this.setState({ edit: item });
                              }}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                this.setState({ trash: item });
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>Não há resultados</>
            )}
          </div>
          <div
            className="card-footer"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {this.renderPagination()}
          </div>
        </div>

        <StaticDialog
          isOpen={this.state.add}
          title="Cadastrar Nova Máquina"
          onAfterClose={(result) => {
            this.setState({ add: false, dados: { price: "R$0,00" } });
          }}
        >
          <div className="content">
            <form id="addMachineForm">
              <div class="mb-3">
                <label for="machineName" class="form-label">
                  Nome da Máquina
                </label>
                <input
                  type="text"
                  class="form-control"
                  onChange={this.handleChange}
                  value={this.state.dados.machineName || ""}
                  id="machineName"
                  required=""
                />
              </div>
              <div class="row md-6 d-flex">
                <div class="col mb-3">
                  <label for="machineState" class="form-label">
                    Estado
                  </label>
                  <select
                    class="form-select"
                    id="machineState"
                    onChange={this.handleChange}
                    value={this.state.dados.machineState || ""}
                    required=""
                  >
                    <option value="" disabled="" selected="">
                      Selecione o estado
                    </option>
                    <option value="Ativa">Ativa</option>
                    <option value="Inatividade">Inatividade</option>
                    <option value="Em Manutenção">Em Manutenção</option>
                  </select>
                </div>
                <div class="col mb-3">
                  <label for="price" class="form-label">
                    Preço
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="price"
                    onChange={(e) => {
                      this.setState((prevState) => ({
                        dados: {
                          ...prevState.dados, // Mantém os outros campos do objeto `dados`
                          ["price"]: this.formatarMoeda(e.target.value), // Atualiza apenas o campo correspondente
                        },
                      }));
                    }}
                    value={this.state.dados.price || ""}
                    required=""
                  />
                </div>
              </div>
              <div class="row md-6 d-flex">
                <div class="col mb-3">
                  <label for="acquisitionDate" class="form-label">
                    Data de Aquisição
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    id="acquisitionDate"
                    onChange={this.handleChange}
                    value={this.state.dados.acquisitionDate || ""}
                    required=""
                  />
                </div>
                <div class="col mb-3">
                  <label for="maintenanceDate" class="form-label">
                    Data de Manutenção
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    id="maintenanceDate"
                    onChange={this.handleChange}
                    value={this.state.dados.maintenanceDate || ""}
                    required=""
                  />
                </div>
              </div>
              <div class="row md-6 d-flex">
                <div class="col mb-3">
                  <label for="stockQuantity" class="form-label">
                    Quantidade Adquirida
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="stockQuantity"
                    onChange={this.handleChange}
                    value={this.state.dados.stockQuantity || ""}
                    required=""
                  />
                </div>
                <div class="col mb-3">
                  <label for="stockQuantity" class="form-label">
                    Estoque Atualizado
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="stockQuantity"
                    onChange={this.handleChange}
                    value={this.state.dados.stockQuantity || ""}
                    required=""
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var dados = this.state.dados;

                if (dados.stockQuantity == undefined) {
                  toast.error("Quantidade em estoque inválida!");
                  return;
                }

                e.target.classList.add("load");

                fetch(`${apiUrl}/cadastrar-maquina`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // outras headers, se necessário
                  },
                  body: JSON.stringify(dados), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.status == 200) {
                      response.json().then((resp) => {
                        var {
                          id,
                          price,
                          machineName,
                          machineState,
                          price,
                          acquisitionDate,
                          maintenanceDate,
                          stockQuantity,
                        } = resp.data;

                        this.setState((prevState) => ({
                          items: [
                            {
                              id,
                              price,
                              machineName,
                              machineState,
                              price,
                              acquisitionDate,
                              maintenanceDate,
                              stockQuantity,
                            },
                            ...prevState.items,
                          ],
                        }));

                        this.setState({ add: false, dados: {} });

                        toast.success("Máquina cadastrada com sucesso!");
                      });
                    }
                  })
                  .catch((err) => {
                    toast.error("Falha na comunicação com o servidor!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.edit).length > 0}
          title="Editar Máquina"
          onAfterClose={(result) => {
            this.setState({ edit: {} });
          }}
        >
          <div className="content">
            <form id="addMachineForm">
              <div class="mb-3">
                <label for="machineName" class="form-label">
                  Nome da Máquina
                </label>
                <input
                  type="text"
                  class="form-control"
                  onChange={this.handleEdit}
                  value={this.state.edit.machineName || ""}
                  id="machineName"
                  required=""
                />
              </div>
              <div class="row md-6 d-flex">
                <div class="col mb-3">
                  <label for="machineState" class="form-label">
                    Estado
                  </label>
                  <select
                    class="form-select"
                    id="machineState"
                    onChange={this.handleEdit}
                    value={this.state.edit.machineState || ""}
                    required=""
                  >
                    <option value="" disabled="" selected="">
                      Selecione o estado
                    </option>
                    <option value="Ativa">Ativa</option>
                    <option value="Inatividade">Inatividade</option>
                    <option value="Em Manutenção">Em Manutenção</option>
                  </select>
                </div>
                <div class="col mb-3">
                  <label for="price" class="form-label">
                    Preço
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="price"
                    onChange={(e) => {
                      console.log(e.target.value);
                      this.setState((prevState) => ({
                        edit: {
                          ...prevState.edit, // Mantém os outros campos do objeto `dados`
                          ["price"]: this.formatarMoeda(e.target.value), // Atualiza apenas o campo correspondente
                        },
                      }));
                    }}
                    value={this.state.edit.price || ""}
                    required=""
                  />
                </div>
              </div>
              <div class="row md-6 d-flex">
                <div class="col mb-3">
                  <label for="acquisitionDate" class="form-label">
                    Data de Aquisição
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    id="acquisitionDate"
                    onChange={this.handleEdit}
                    value={this.state.edit.acquisitionDate || ""}
                    required=""
                  />
                </div>
                <div class="col mb-3">
                  <label for="maintenanceDate" class="form-label">
                    Data de Manutenção
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    id="maintenanceDate"
                    onChange={this.handleEdit}
                    value={this.state.edit.maintenanceDate || ""}
                    required=""
                  />
                </div>
              </div>
              <div class="row md-6 d-flex">
                <div class="col mb-3">
                  <label for="stockQuantity" class="form-label">
                    Quantidade Adquirida
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="stockQuantity"
                    onChange={this.handleEdit}
                    value={this.state.edit.stockQuantity || ""}
                    required=""
                  />
                </div>
                <div class="col mb-3">
                  <label for="stockQuantity" class="form-label">
                    Estoque Atualizado
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="stockQuantity"
                    onChange={this.handleEdit}
                    value={this.state.edit.stockQuantity || ""}
                    required=""
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var dados = this.state.edit;

                fetch(`${apiUrl}/atualizar-maquina`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // outras headers, se necessário
                  },
                  body: JSON.stringify(dados), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    console.log(response);
                    if (response.status == 200) {
                      response.json().then((data) => {
                        var {
                          id,
                          machineName,
                          machineState,
                          price,
                          acquisitionDate,
                          maintenanceDate,
                          stockQuantity,
                        } = this.state.edit;

                        var newItems = {
                          machineName,
                          machineState,
                          price,
                          acquisitionDate,
                          maintenanceDate,
                          stockQuantity,
                        };

                        this.setState((prevState) => ({
                          items: prevState.items.map((item) =>
                            item.id === id ? { ...item, ...newItems } : item
                          ),
                        }));

                        this.setState({ edit: {} });

                        toast.success("Máquina atualizada");
                      });
                    }
                  })
                  .catch((err) => {
                    toast.error("Falha na comunicação com o servidor!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Tem certeza de que deseja continuar?"
          onAfterClose={(result) => {
            this.setState({ trash: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            Se você confirmar a exclusão, não será possível recuperar os dados
            posteriormente.
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                var id = this.state.trash.id;
                fetch(`${apiUrl}/deletar-maquina`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // outras headers, se necessário
                  },
                  body: JSON.stringify({ id }), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.ok) {
                      this.setState((prevState) => ({
                        items: prevState.items.filter((item) => item.id !== id),
                        trash: {},
                      }));

                      toast.success("Equipamento excluido com sucesso");
                    }
                  })
                  .catch((error) => {
                    toast.error("Erro ao enviar dados:", error);
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Excluir
            </button>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default CadastroMaquina;
