import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import atencao from "../../img/atencao.svg"
class P404 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

componentDidMount(){
    document.title = "Página não encontrada"
}

  render() {


    return (
  <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',marginTop:-80,height:'100vh'}}>

<img src={atencao} style={{width:'30%'}} />
<div style={{marginTop:10}}>
    <strong>
    Desculpe, a página que você está procurando não existe.
    </strong>
</div>

  </div>
    );
  }
}

export default P404;
