import React from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import Header from "./pages/Dashboard/Header";
import P404 from "./pages/P404";
import CadastroClientes from "./pages/CadastroClientes";
import CadastroMaquina from "./pages/CadastroMaquina";
import Budget from "./pages/Budget";
import NewBudget from "./pages/Budget/new";
import ViewBudget from "./pages/Budget/ViewBudget";
import EditBudget from "./pages/Budget/EditBudget";
import Login from "./pages/Login";
import Lease from "./pages/Leaser";
import ViewLeaser from "./pages/Leaser/ViewLeaser";
import LeaseHistoy from "./pages/Leaser/LeaseHistoy";
import Services from "./pages/Services";

function App() {
  const navigate = useNavigate();

  // Verifica se o usuário está autenticado
  const isAuthenticated = !!localStorage.getItem("token");

  return (
    <>
      {isAuthenticated ? (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<Lease navigate={navigate} />} />
            <Route
              path="/lease/view/:token"
              element={<ViewLeaser navigate={navigate} />}
            />
            <Route
              path="/lease-history"
              element={<LeaseHistoy navigate={navigate} />}
            />

            <Route path="/cadastro-clientes" element={<CadastroClientes />} />
            <Route path="/cadastro-maquinas" element={<CadastroMaquina />} />
            <Route path="/budget" element={<Budget />} />
            <Route
              path="/budget/new"
              element={<NewBudget navigate={navigate} />}
            />
            <Route
              path="/budget/view/:token"
              element={<ViewBudget navigate={navigate} />}
            />
            <Route
              path="/budget/edit/:token"
              element={<EditBudget navigate={navigate} />}
            />
            <Route path="/services" element={<Services />} />
            <Route path="*" element={<P404 />} />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
      <ToastContainer />
    </>
  );
}

export default App;
