import React, { Component } from 'react';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount(){
    document.title = "Dashboard"
  }
  render() {


    return (
  <></>
    );
  }
}

export default Dashboard;
