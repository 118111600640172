import React, { Component } from "react";
import { apiUrl } from "../../../comps/apiUrl";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, fawhatsapp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import alert from "../../../img/alert.svg";
class ViewBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      data: [],
      error: false,
    };
  }

  componentDidMount() {
    document.title = "Visualizar orçamento";
    fetch(`${apiUrl}/view-budget/${window.location.pathname.split("/")[3]}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 404) {
          this.setState({
            loader: false,
            error: true,
          });
        }

        if (response.status == 200) {
          response.json().then((data) => {
            this.setState({
              data: data.dados,
              loader: false,
            });
          });
        }
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao carregar as maquinas");
      });
  }

  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }
    if (this.state.error) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "80vh",
          }}
        >
          <img
            src={alert}
            style={{ width: "400px", top: 100 }}
            alt="imagem de alerta"
          />
          <div>
            Ops! O documento que você está procurando não foi encontrado.
          </div>
        </div>
      );
    }
    var data = this.state.data;
    return (
      <div class="content d-flex justify-content-center">
        <div class="col-8 mt-5">
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <strong>
                Orçamento{" "}
                {data.ClientID != undefined && (
                  <>
                    de: {data.nome} - {data.doc}
                  </>
                )}
              </strong>
              <Link
                to={`/budget/edit/${window.location.pathname.split("/")[3]}`}
                className="btn btn-primary"
              >
                <FontAwesomeIcon icon={faPen} /> Editar
              </Link>
            </div>
            <div class="card-body">
              <table class="table table-striped table-bordered arvore-l mt-2">
                <thead class="thead-dark">
                  <tr></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Equipamento</td>
                    <td>{data.machineName}</td>
                  </tr>
                  <tr>
                    <td>Quantidade</td>
                    <td>{data.quantidade}</td>
                  </tr>
                  <tr>
                    <td>Valor unitário</td>
                    <td>
                      {Number(data.price).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </td>
                  </tr>

                  <tr>
                    <td>Data inicial</td>
                    <td>{this.formatDate(data.startDate)}</td>
                  </tr>

                  <tr>
                    <td>Data final</td>
                    <td>{this.formatDate(data.endDate)}</td>
                  </tr>

                  <tr>
                    <td>dias</td>
                    <td>{data.days}</td>
                  </tr>

                  <tr>
                    <td>Valor total</td>
                    <td>
                      {Number(data.total).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {data.telefone_celular != undefined && (
              <>
                <div className="card-footer d-flex justify-content-end">
                  <button
                    className="btn b-wpp"
                    onClick={(e) => {
                      e.target.classList.add("load");
                      fetch(
                        `${apiUrl}/send-budget/${
                          window.location.pathname.split("/")[3]
                        }`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({}),
                        }
                      )
                        .then((response) => {
                          e.target.classList.remove("load");
                          toast.success("Orçamento enviado com sucesso");
                        })
                        .catch((error) => {
                          e.target.classList.remove("load");
                          toast.error("Ocorreu um erro ao enviar o orcamento");
                        });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      viewBox="0 0 448 512"
                    >
                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                    </svg>
                    Enviar orçamento
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ViewBudget;
