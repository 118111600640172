import React, { Component } from "react";
import "./l.css";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, fawhatsapp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import logo from "../../img/logo.webp";
import { apiUrl } from "../../comps/apiUrl";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      pass: "",
    };
  }

  componentDidMount() {
    document.title = "Rb Hidraulicos - Login";
  }
  handleChange = (event) => {
    const { id, value } = event.target;
    this.setState({ [id]: value });
  };
  render() {
    var { user, pass } = this.state;
    return (
      <section className="area-login">
        <div className="logo-l">
          <img src={logo} alt="Logo" />
        </div>
        <div class="login-container">
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (user.trim().length == 0) {
                toast.warning("Preencha o usuário");
                return;
              }
              if (pass.trim().length == 0) {
                toast.warning("Preencha a senha");
                return;
              }
              e.target[2].classList.add("load");
              fetch(`${apiUrl}/login`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ user, pass }),
              })
                .then((response) => {
                  e.target[2].classList.remove("load");
                  if (response.status == 200) {
                    response.json().then((data) => {
                      localStorage.setItem("token", data.token);
                      window.location = "/";
                    });
                  }
                  if (response.status == 403) {
                    return toast.warning(
                      "Você excedeu o número permitido de tentativas de login. Por favor, aguarde alguns minutos antes de tentar novamente."
                    );
                  }
                  if (response.status == 404) {
                    response.json().then((data) => {
                      return toast.error(data.error);
                    });
                  }
                })
                .catch((error) => {
                  e.target[2].classList.remove("load");
                  toast.error("Ocorreu um erro");
                });
            }}
          >
            <div class="mb-3">
              <input
                type="text"
                class="form-control"
                id="user"
                placeholder="Usuário:"
                value={user}
                onChange={this.handleChange}
              />
            </div>
            <div class="mb-3">
              <input
                type="password"
                class="form-control"
                id="pass"
                placeholder="Senha:"
                value={pass}
                onChange={this.handleChange}
              />
            </div>
            <button type="submit" class="btn btn-primary w-100">
              Entrar
            </button>
          </form>
        </div>
      </section>
    );
  }
}

export default Login;
